import React from "react";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PersonalFinancial({
    personalFinancial,
    handlePersonalFinancialChange
}) {
    return (
        <Card className='padding'>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Marital Status</Form.Label>
                    <Form.Control
                        type="text"
                        name="maritalStatus"
                        value={personalFinancial?.maritalStatus}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Combined Income</Form.Label>
                    <Form.Control
                        type="text"
                        name="combinedIncome"
                        value={personalFinancial?.combinedIncome}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Occupation</Form.Label>
                    <Form.Control
                        type="text"
                        name="occupation"
                        value={personalFinancial?.occupation}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Monthly Housing Cost</Form.Label>
                    <Form.Control
                        type="text"
                        name="monthlyHousingCost"
                        value={personalFinancial?.monthlyHousingCost}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Monthly Expenses</Form.Label>
                    <Form.Control
                        type="text"
                        name="monthlyExpenses"
                        value={personalFinancial?.monthlyExpenses}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Number of Children</Form.Label>
                    <Form.Control
                        type="number"
                        name="numOfChildren"
                        value={personalFinancial?.numOfChildren}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label>Savings</Form.Label>
                    <Form.Control
                        type="text"
                        name="savings"
                        value={personalFinancial?.savings}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>Credit Available</Form.Label>
                    <Form.Control
                        type="text"
                        name="creditAvailable"
                        value={personalFinancial?.creditAvailable}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.value)}
                        disabled
                    />
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col}>
                    <Form.Check
                        type="checkbox"
                        name="ownHome"
                        label="Own Home"
                        checked={personalFinancial?.ownHome}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Check
                        type="checkbox"
                        name="rentHome"
                        label="Rent Home"
                        checked={personalFinancial?.rentHome}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                        disabled
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Check
                        type="checkbox"
                        name="carPaidOff"
                        label="Car Paid Off"
                        checked={personalFinancial?.carPaidOff}
                        onChange={(e) => handlePersonalFinancialChange(e.target.name, e.target.checked)}
                        disabled
                    />
                </Form.Group>
            </Row>
        </Card>
    );
}

export default PersonalFinancial;