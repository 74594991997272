import React, { useContext, useEffect, useState } from 'react'
import AccountDetailsPopupView from './AccountDetailsPopupView'
import { CustomerContext } from '../../CustomerContext/CustomerContext'
import useFetchGet from '../../Fetch/useFetchGet'

function AccountDetailsPopup({ show, setShow, debtorId }) {
  const { REACT_APP_API_URL } = process.env;
  const { cookies } = useContext(CustomerContext)
  const [accountDetails, setAccountDetails] = useState();
  const [adverseCarrier, setAdverseCarrier] = useState();
  const [activities, setActivities] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [personalFinancial, setPersonalFinancial] = useState();
  const [statusCode, setstatusCode] = useState();
  const [accountBalance, setAccountBalance] = useState();
  const [financial, setFinancial] = useState();

  const { data: accountDetailsArray } = useFetchGet(`${REACT_APP_API_URL}/GetAccountDetail?Token=${cookies.get('customer').Token}&debtorId=${debtorId}`, cookies)

  useEffect(() => {
    if (accountDetailsArray) {

      setAccountDetails(accountDetailsArray.account);
      setActivities(accountDetailsArray.activities);
      setAdverseCarrier(accountDetailsArray.adverseCarrier);
      setstatusCode(accountDetailsArray.statusCode);
      setPaymentHistory(accountDetailsArray.paymentHistory[0] != null ? accountDetailsArray.paymentHistory : '');
      setAccountBalance(accountDetailsArray.accountBalance);
      setFinancial(accountDetailsArray.financial ? accountDetailsArray.financial : []);
      setPersonalFinancial(accountDetailsArray.personalFinancial);
    }
  }, [accountDetailsArray])
  return (
    <AccountDetailsPopupView
      show={show}
      setShow={setShow}
      accountDetails={accountDetails}
      activities={activities}
      adverseCarrier={adverseCarrier}
      paymentHistory={paymentHistory}
      personalFinancial={personalFinancial}
      accountBalance={accountBalance}
      statusCode={statusCode}
      financial={financial}
    ></AccountDetailsPopupView>
  )
}

export default AccountDetailsPopup